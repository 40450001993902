import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Helmet} from 'react-helmet';
import {Document} from 'sally-ui';

import Container from './components/Container';

const Privacy = ({
  html,
}) => (
  <Container>
    <Helmet>
      <title>
        Privacy Policy
      </title>
    </Helmet>
    <Document html={html} dynamic />
  </Container>
);

Privacy.propTypes = {
  html: PropTypes.string,
};

export default Privacy;
