import styled from 'styled-components';

// Colors
import {lib} from 'sally-ui';
const {
  colors: {white},
} = lib;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000000000;
  background: ${white};
  pointer-events: ${({visible}) => (visible ? 'auto' : 'none')};
  opacity: ${({visible}) => (visible ? 1 : 0)};
  visibility: ${({visible}) => (visible ? 'visible' : 'hidden')};
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
`;

export default Container;
