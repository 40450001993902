import React from 'react';
import {render} from 'react-dom';
import {BrowserRouter as Router, Route} from 'react-router-dom';

import 'sally-fn/lib/api/profiles.api';

// App
import {App} from './components/app/App';

render(
  <Router>
    <Route component={App} />
  </Router>,
  document.getElementById('root')
);
