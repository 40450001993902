import styled from 'styled-components';

// Lib
import {lib} from 'sally-ui';

// Colors
const {
  colors: {grey},
} = lib;

const Fix = styled.div`
  width: 100%;
  position: fixed;
  padding: 20px;
  bottom: 0px;
  left: 0px;
  background: ${grey};
  transition: opacity 0.4s ease-in-out;
  z-index: 10;
`;

export default Fix;
