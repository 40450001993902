import React from 'react';

// Components
import {Page} from '../../app/Page';

// Containers
import PolicyContainer from '../../containers/PolicyContainer/PolicyContainer';

const HomePage = () => (
  <Page>
    <PolicyContainer />
  </Page>
);

export default HomePage;
