import React from 'react';

// Components
import {Page} from '../../app/Page';

// Containers
import PrivacyContainer from '../../containers/PrivacyContainer/PrivacyContainer';

const PrivacyPage = ({
  match: {
    params: {token},
  },
}) => (
  <Page>
    <PrivacyContainer token={token} />
  </Page>
);

export default PrivacyPage;
