import styled from 'styled-components';

// Libs
import {lib} from 'sally-ui';

// Colors
const {
  colors: {grey},
} = lib;

const Container = styled.div`
  width: 100vw;
  background: ${grey};
  display: block;
  position: relative;
`;

export default Container;
