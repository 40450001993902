import styled from 'styled-components';

const Content = styled.div`
  width: 100%;
  max-width: 400px;
  margin: auto;
  transform: ${({move}) => (move ? 'translateY(-62px)' : 'none')};
  transition: transform 0.4s ease-in-out;
`;

export default Content;
