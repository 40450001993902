import React from 'react';

// Components
import Container from './components/Container';
import Content from './components/Content';
import Spinner from './components/Spinner';

const Loader = () => (
  <Container>
    <Content>
      <Spinner icon="loading2" spin />
    </Content>
  </Container>
);

export default Loader;
