import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Row, Column, Button} from 'sally-ui';

import Container from './components/Container';
import Fix from './components/Fix';
import FixContent from './components/FixContent';
import Content from './components/Content';
import Buttons from './components/Buttons';

const ActionButtons = ({
  display,
  accepted,
  loading,
  scrolled,
  onAccept,
  onDecline,
}) => (
  <Container>
    <Fix display={display ? 1 : 0}>
      <FixContent>
        <Content move={scrolled}>
          <Row margin>
            <Column>
              <Buttons>
                <Button disabled={loading}>Read above and scroll down</Button>
              </Buttons>
            </Column>
          </Row>
          <Row>
            <Column size={1 / 2}>
              <Button
                theme="grey"
                disabled={loading && accepted}
                loading={loading && !accepted}
                onClick={onDecline}
                block
              >
                I Don't Agree
              </Button>
            </Column>
            <Column size={1 / 2}>
              <Button
                theme="red"
                disabled={loading && !accepted}
                loading={loading && accepted}
                onClick={onAccept}
                block
              >
                I Agree
              </Button>
            </Column>
          </Row>
        </Content>
      </FixContent>
    </Fix>
  </Container>
);

ActionButtons.propTypes = {
  display: PropTypes.bool,
  accepted: PropTypes.bool,
  loading: PropTypes.bool,
  scrolled: PropTypes.bool,
  onAccept: PropTypes.func,
  onDecline: PropTypes.func,
};

export default ActionButtons;
