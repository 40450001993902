import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Helmet} from 'react-helmet';
import {Document} from 'sally-ui';
import Success from '../Success/Success';
import ActionButtons from '../ActionButtons/ActionButtons';

import Container from './components/Container';

// Styles
import './styles.css';

const PrivacyDoc = ({
  loading,
  done,
  display,
  scrolled,
  accepted,
  first_name,
  last_name,
  children,
  html,
  onAccept,
  onDecline,
}) => (
  <Container>
    <Helmet>
      <title>
        {first_name} {last_name} Privacy Update
      </title>
    </Helmet>
    {display && <Document html={html} dynamic />}
    {display && (
      <ActionButtons
        display={!done}
        loading={loading}
        accepted={accepted}
        scrolled={scrolled}
        onAccept={onAccept}
        onDecline={onDecline}
      />
    )}
    <Success accepted={accepted} done={done}>
      {children}
    </Success>
  </Container>
);

PrivacyDoc.propTypes = {
  loading: PropTypes.bool,
  done: PropTypes.bool,
  display: PropTypes.bool,
  scrolled: PropTypes.bool,
  accepted: PropTypes.bool,
  children: PropTypes.node,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  html: PropTypes.string,
  onAccept: PropTypes.func,
  onDecline: PropTypes.func,
};

PrivacyDoc.defaultProps = {
  display: true,
};

export default PrivacyDoc;
