import React from 'react';
import {Switch, Route} from 'react-router-dom';

// Home
import HomePage, {route as routeHome} from '../../pages/HomePage';
import PrivacyPage, {route as routePrivacy} from '../../pages/PrivacyPage';
import NotFoundPage, {route as notFoundRoute} from '../../pages/NotFoundPage/';

const Routes = () => (
  <Switch>
    <Route exact path={routeHome()} component={HomePage} />
    <Route exact path={notFoundRoute()} component={NotFoundPage} />
    <Route exact path={routePrivacy()} component={PrivacyPage} />
    <Route component={NotFoundPage} />
  </Switch>
);

export default Routes;
