import styled from 'styled-components';

// Components
import {Icon} from 'sally-ui';

// Libs
import {lib} from 'sally-ui';

// Colors
const {
  colors: {red},
} = lib;

const Spinner = styled(Icon)`
  font-size: 80px !important;
  color: ${red};
`;

export default Spinner;
