import React, {Component} from 'react';
import PropTypes from 'prop-types';
import jwt from 'jsonwebtoken';
import $ from 'jquery';

// Api
import {privacyPolicyAgreement} from 'sally-fn/lib/driver/api';
import {getGoogleDoc} from 'sally-fn/lib/document/api';

// Components
import PrivacyDoc from '../../components/PrivacyDoc/PrivacyDoc';
import Link from '../../components/Link/Link';
import Loader from '../../components/Loader/Loader';

// Constants
import {privacy as privacyGoogleID} from 'sally-fn/lib/document/docs';

// Libs
import {lib} from 'sally-ui';
import {redirect} from '../../../libs/appUrl';
import sallyError from '../../../libs/sallyError';

// Helpers
const {alert, notify} = lib;

class PrivacyContainer extends Component {
  static propTypes = {
    token: PropTypes.string,
  };

  state = {
    loading: false,
    driver: null,
    html: '',
    done: false,
    display: true,
    scrolled: false,
    accepted: false,
  };

  componentDidMount() {
    this.init();
  }

  init = async () => {
    try {
      const driver = jwt.decode(this.props.token);
      if (!driver.driver_id) return redirect('/nope');
      const html = await getGoogleDoc(privacyGoogleID);
      await this.setState({driver, html: `${html}`});
      this.scroller();
    } catch (error) {
      redirect('/nope');
    }
  };

  scroller = () => {
    const {scrolled} = this.state;
    $(window).scroll(() => {
      if (scrolled) return;
      if (
        $(window).scrollTop() + $(window).height() >=
        $(document).height() - 200
      )
        this.setState({scrolled: true});
    });
  };

  onAccept = () => this.agreement(true);
  onDecline = () => this.agreement(false);

  agreement = async (accepted, prompt = true) => {
    const {token} = this.props;
    const {loading, driver, done} = this.state;

    if (!token || loading || !driver || done) return;

    if (!accepted && prompt)
      return notify({
        id: 'disagreeing',
        title: 'Please confirm',
        icon: undefined,
        content:
          'You must accept our Privacy Policy in order to continue driving.',
        primary: {
          label: 'Try Again',
          onClick: () => null,
        },
        secondary: {
          label: "I don't agree",
          onClick: () => this.agreement(false, false),
        },
        closable: false,
        closeOnOutsideClick: true,
      });

    this.setState({accepted, loading: true});

    try {
      await privacyPolicyAgreement(driver.driver_id, {token, accepted});
      this.setState({loading: false, done: true});
      setTimeout(() => this.setState({display: false}), 220);
    } catch (error) {
      const {message} = sallyError(error);
      alert.error(message);
      this.setState({loading: false});
    }
  };

  render() {
    const {
      scrolled,
      display,
      accepted,
      driver,
      html,
      loading,
      done,
    } = this.state;
    return !driver ? (
      <Loader />
    ) : (
      <PrivacyDoc
        html={html}
        loading={loading}
        done={done}
        display={display}
        scrolled={scrolled}
        accepted={accepted}
        first_name={driver.first_name}
        last_name={driver.last_name}
        onAccept={this.onAccept}
        onDecline={this.onDecline}
      >
        {accepted ? (
          <span>
            Be Driven. Drive Sally.
            <br />
            <Link href="https://drivesally.com" target="_blank">
              drivesally.com
            </Link>
          </span>
        ) : (
          <span>
            Your selection has been recorded.
            <br />
            Please call <Link href="tel:6468834280">646-883-4280</Link> to speak
            with a representative.
            <br />
            <Link href="https://drivesally.com" target="_blank">
              drivesally.com
            </Link>
          </span>
        )}
      </PrivacyDoc>
    );
  }
}

export default PrivacyContainer;
