let browserHistory = {
  push: () => null,
  replace: () => null,
};

/**
 * @description :: Setting the history object
 * @param {Object} history :: The history object
 */
export const setHistory = history => {
  browserHistory = history;
};

/**
 * @description :: Adding a forward slash to the beginning of the url
 * @param {String} url :: Url stromg
 * @return {String} :: Formatted url
 */
export const prepareUrl = url => `${url.startsWith('/') ? '' : '/'}${url}`;

/**
 * @description :: Function for redirecting the url
 * @param {String} url :: The url we want to go to
 */
export const goTo = url => {
  browserHistory.push(prepareUrl(url));
};

/**
 * @description :: Replaceing the current url
 * @param {String} url :: The url we want to replace the current url with
 */
export const redirect = url => {
  browserHistory.replace(prepareUrl(url));
};

/**
 * @description :: Check two page urls
 * @param {String} urlToCheck :: First url
 * @param {String} url :: Second url
 * @return {Boolean} :: If same urls or not
 */
export const isOnSamePage = (urlToCheck, url = window.location.pathname) => prepareUrl(urlToCheck) === prepareUrl(url);
