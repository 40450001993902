import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Components
import {Card, P} from 'sally-ui';

import Container from './components/Container';
import Content from './components/Content';
import Icon from './components/Icon';

// Styles
import './styles.css';

class Success extends Component {
  static propTypes = {
    accepted: PropTypes.bool,
    done: PropTypes.bool,
    children: PropTypes.node,
  };

  state = {
    animate: false,
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.done && this.props.done)
      setTimeout(() => this.setState({animate: true}), 200);
  }

  render() {
    const {animate} = this.state;
    const {accepted, done, children} = this.props;
    return (
      <Container visible={done}>
        <Content>
          <Card shadow>
            {(CardContent) => (
              <CardContent noBorder>
                <div className={animate ? 'submitted' : ''}>
                  <div className="card-success">
                    <div className="icon">
                      {accepted ? (
                        <svg
                          width="84px"
                          height="84px"
                          viewBox="0 0 84 84"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                        >
                          <circle
                            className="border"
                            cx="42"
                            cy="42"
                            r="40"
                            strokeLinecap="round"
                            strokeWidth="4"
                            stroke="#000"
                            fill="none"
                          />
                          <path
                            className="checkmark"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M23.375 42.5488281 36.8840688 56.0578969 64.891932 28.0500338"
                            strokeWidth="4"
                            stroke="#000"
                            fill="none"
                          />
                        </svg>
                      ) : (
                        <Icon icon="close" />
                      )}
                    </div>
                    {accepted && (
                      <div className="title">
                        <P size="3" weight="bold" align="center">
                          All done!
                        </P>
                      </div>
                    )}
                    <P theme="darkGrey" align="center">
                      {children}
                    </P>
                  </div>
                </div>
              </CardContent>
            )}
          </Card>
        </Content>
      </Container>
    );
  }
}

export default Success;
