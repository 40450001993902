import styled from 'styled-components';

import {lib} from 'sally-ui';

// Colors
const {
  colors: {red},
} = lib;

const Link = styled.a`
  display: inline-block;
  color: ${red};
  margin-top: 10px;
  cursor: pointer;
`;

export default Link;
