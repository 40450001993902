import {Component} from 'react';
import PropTypes from 'prop-types';

class Page extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return this.props.children;
  }
}

export default Page;
